<template>
  <div style="min-height: 100vh;">
    <heard />
    <div class="main" style="margin: 24px; display: block; height: calc(100vh - 91px);">
      <div class="layout" style="width: 100%;max-width: 1200px">
        <div style="display: flex; align-items: center;">
          <button class="return_btn" @click="$router.go(-1)">
            <span>&lt;&nbsp;</span>
            <span>返回</span>
          </button>
        </div>
        <div style="color: rgb(36, 40, 51); font-size: 30px; line-height: 42px; font-weight: bold; margin: 8px 0 24px;text-align: left">
          购买套餐
        </div>
        <div class="layout-main">
          <div class="layout-content">
            <div class="package-pay-container">
              <div class="package-pay-card-container">
                <div class="sm-card card">
                  <div class="card-head">
                    <p>{{ `${listData.name}  (${payTimeType == 0 ? '月' : '年'}付)` }}</p>
                  </div>
                  <a-table
                    :columns="columns"
                    :data-source="tableData"
                    :pagination="false"
                    :row-key="(tableData,index)=>{return index}"
                  >
                    <span slot="time" slot-scope="time,record">
                      {{ time + '至' + record.endTime }}
                    </span>
                  </a-table>
                  <h4 class="formItemTitle">
                    付费周期
                  </h4>
                  <a-radio-group v-model="payTimeType" name="radioGroup" :default-value="1" style="display: flex;flex-direction: column;align-items: flex-start;" @change="payTimeTypeChange">
                    <a-radio :style="radioStyle" :value="0" v-if="listData.type != 'V4'">
                      按月缴费
                    </a-radio>
                    <a-radio :style="radioStyle" :value="1">
                      按年缴费
                    </a-radio>
                  </a-radio-group>
                </div>
                <div class="sm-card card">
                  <div class="card-head">
                    <p>待支付 ￥{{ totalPrice - countPrice }}</p>
                  </div>
                  <div class="card-body">
                    <div class="card-container">
                      <a-radio-group v-model="payType" name="radioGroup" :default-value="1" style="display: flex;flex-direction: column;align-items: flex-start;" @change="payTypeChange">
                        <a-radio :style="payTypeStyle" :value="1">
                          <span style="display: inline-flex;align-items: center">
                            <i class="iconfont icon-weixin" />
                            <span>微信支付</span>
                          </span>
                        </a-radio>
                        <a-radio :style="payTypeStyle" :value="2">
                          <span style="display: inline-flex;align-items: center">
                            <i class="iconfont icon-zhifubao" />
                            <span>支付宝支付</span>
                          </span>
                        </a-radio>
                        <a-radio :style="payTypeStyle" :value="5">
                          <span style="display: inline-flex;align-items: center">
                            <i class="iconfont icon-zhifubao" />
                            <span>支付宝周期付款</span>
                          </span>
                        </a-radio>
                      </a-radio-group>
                    </div>
                  </div>
                </div>
              </div>
              <div class="package-pay-small-card-container">
                <div class="sm-card card">
                  <div class="card-head">
                    <p>优惠码/推荐码</p>
                  </div>
                  <div class="card-body">
                    <div class="discount-code-content">
                      <div class="inputBox">
                        <input type="text" placeholder="请输入优惠码/推荐码">
                      </div>
                      <el-button type="primary" style="height: 36px">
                        应用
                      </el-button>
                    </div>
                  </div>
                </div>
                <div class="sm-card card">
                  <div class="card-head">
                    <p>账单明细</p>
                  </div>
                  <div class="card-body">
                    <div class="total-container">
                      <span>小计</span>
                      <span>￥{{ totalPrice }}</span>
                    </div>
                    <div class="total-container">
                      <span>折扣</span>
                      <span>￥{{ countPrice }}</span>
                    </div>
                    <div class="total-container">
                      <span>合计</span>
                      <span>￥{{ totalPrice - countPrice }}</span>
                    </div>
                    <div class="total-container realPay-container">
                      <span>实付</span>
                      <span>￥{{ totalPrice - countPrice }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="package-pay-footer-divider" />
            <div style="display: flex;flex-direction: row-reverse;width: 100%;">
              <el-button type="primary" style="height: 36px" @click="submitPayOrder">
                去支付
              </el-button>
            </div>
          </div>
        </div>
      </div>
      <!--  modal    -->
      <a-modal v-model="visible" title="微信支付">
        <p class="price">
          ￥{{ totalPrice - countPrice }}
        </p>
        <p class="desc">
          请使用微信扫码支付
        </p>
        <div style="margin: 12px 0 0 0;padding: 20px 0;text-align: center;background: #F5F8FC;">
          <vue-qr :logo-src="require('../../../assets/img/system/wxchat.png')" :text="qrcode" :size="300" :margin="30" :white-margin="true" />
        </div>
        <template slot="footer">
          <a-button key="back" @click="handleModalCancel">
            取消
          </a-button>
          <!-- <a-button key="submit" type="primary" :loading="ModalLoading" @click="handleModalOk">
            完成
          </a-button> -->
        </template>
      </a-modal>
      <a-modal v-model="alipayVisible" title="支付宝自动扣款">
        <p class="price">
          ￥{{ totalPrice - countPrice }}
        </p>
        <p class="desc">
          请使用支付宝扫码签约
        </p>
        <div style="margin: 12px 0 0 0;padding: 20px 0;text-align: center;background: #F5F8FC;">
          <vue-qr :margin="30" :size="300" :text="alipayQrcode" :white-margin="true" />
        </div>
        <template slot="footer">
          <a-button key="back" @click="handleAlipayModalCancel">
            取消
          </a-button>
          <!-- <a-button key="submit" type="primary" :loading="ModalLoading" @click="handleModalOk">
            完成
          </a-button> -->
        </template>
      </a-modal>
    </div>
  </div>
</template>

<script>
import vueQr from 'vue-qr'
import { getShopPackage, getusernameToken } from '@/utils/cookie'
// eslint-disable-next-line no-unused-vars
import { addOrder, queryWxOrder, unifiedOrder } from '@/api/register'
import heard from '../components/heard'
import { dateFormat } from '@/utils/formatDate'
import { queryOrderAliPaySign } from '../../../api/shopOrder'

const columns = [
  {
    title: '名称',
    dataIndex: 'name',
    width: 150
  },
  {
    title: '价格',
    dataIndex: 'price',
    width: 150
  },
  {
    title: '起止时间',
    dataIndex: 'startTime',
    scopedSlots: { customRender: 'time' }
  }
]
export default {
  name: 'OrderPay',
  components: {
    heard,
    vueQr
  },
  data() {
    return {
      // 轮询操作
      setIVal: '',
      maxtime: 30, // 倒计时：秒数
      maxtimesetIVal: '',
      alipayVisible: false, // 支付宝周期扣款二维码
      alipayQrcode: '', // 二维码地址
      interval: '', // 轮询定时器
      qrcode: '', // 微信二维码
      orderId: '', // 订单ID
      orderNo: '', // 订单号
      ModalLoading: false, // modal 按钮等待
      visible: false, // 微信modal 显示控制
      totalPrice: 0, // 合计
      nowDate: '', // 当前时间
      countPrice: 0, // 折扣价格
      listData: {}, // COOKIE数据对象
      radioStyle: {
        display: 'block',
        height: '30px',
        lineHeight: '30px'
      },
      payTypeStyle: {
        display: 'inline-flex',
        fontSize: '16px',
        color: '#242833',
        alignItems: 'center'
      },
      payTimeType: 0, // 0月付 1年付
      payType: 1, // 1微信支付 2支付宝支付 5支付宝周期扣款
      columns,
      tableData: [
        {
          name: '基础版',
          price: '180',
          startTime: '2021-11-10',
          endTime: '2021-12-10'
        }
      ]
    }
  },
  watch: {
    // 轮询获取支付状态
    visible(val) {
      if (!val) {
        clearInterval(this.setIVal)
        clearInterval(this.maxtimesetIVal) // 清空倒计时
      }
    },
    alipayVisible(val) {
      if (!val) {
        clearInterval(this.setIVal)
        clearInterval(this.maxtimesetIVal) // 清空倒计时
      }
    }
  },
  created() {
    // 获取 COOKIE 数据
    this.listData = JSON.parse(getShopPackage())
    if (!(Object.keys(this.listData).length > 0)) {
      this.$router.push({ name: 'StorePackage' })
    }
    this.nowDate = new Date()
    this.tableData[0] = {
      name: this.listData.name,
      price: this.listData.priceMonth,
      startTime: dateFormat('YYYY-mm-dd', this.nowDate),
      endTime: dateFormat('YYYY-mm-dd', new Date(this.nowDate.getTime() + 24 * 60 * 60 * 1000 * 30))
    }
    this.changeTotalPrice()
  },
  mounted() {
    if (this.listData.type === 'V4') {
      this.payTimeType = 1
      this.payTimeTypeChange({
        target: {
          value: 1
        }
      })
    }
  },
  methods: {

    getusernameToken() {
      return getusernameToken()
    },

    // 付款方式改变
    payTypeChange(e) {
      console.log(e.target.value)
    },
    payTimeTypeChange(e) {
      // 按月付款
      if (e.target.value === 0) {
        this.tableData[0].price = this.listData.priceMonth
        this.tableData[0].endTime = dateFormat('YYYY-mm-dd', new Date(this.nowDate.getTime() + 24 * 60 * 60 * 1000 * 30))
        this.changeTotalPrice()
      }
      // 按年付款
      if (e.target.value === 1) {
        this.tableData[0].price = this.listData.nameYear
        this.tableData[0].endTime = dateFormat('YYYY-mm-dd', new Date(this.nowDate.getTime() + 24 * 60 * 60 * 1000 * 365))
        this.changeTotalPrice()
      }
    },
    // 重新获取总价格
    changeTotalPrice() {
      let p = 0
      this.tableData.forEach((item) => {
        p += item.price * 1
      })
      this.totalPrice = p
    },
    // 提交订单
    submitPayOrder() {
      addOrder(
        { 'comboCode': this.listData.type,
          'payTimeType': this.payTimeType,
          'paymentType': this.payType
        }
      ).then((res) => {
        if (res.code === 0) {
          this.orderNo = res.data.orderNo
          this.orderId = res.data.id
          this.getPayDesc()
        }
      })
    },
    // 提交订单之后的回调
    getPayDesc() {
      const that = this
      // 提交订单前去掉所有循环
      clearInterval(that.maxtimesetIVal) // 清空倒计时
      clearInterval(that.setIVal) // 清空循环查询

      const obj = { 'paymentType': this.payType, 'orderNo': this.orderNo, 'id': this.orderId }
      // 支付宝支付
      if (this.payType === 2) {
        obj.tradeType = 3
      }

      // 微信支付
      if (this.payType === 1) {
        this.visible = true
      }
      // 支付宝周期扣款
      if (this.payType === 5) {
        this.alipayVisible = true
      }
      unifiedOrder(obj).then((res) => {
        console.log(res)
        if (this.payType === 2) {
          window.location.href = res.data
        }

        // 支付宝周期扣款
        if (this.payType === 5) {
          this.alipayQrcode = res.data
          // 轮询
          that.setIVal = setInterval(() => {
            queryOrderAliPaySign(that.orderId).then((res) => {
              console.log(res)
              if (res.code === 0) {
                // 支付完成
                console.log('支付完成')
                that.alipayVisible = false
                // 跳页面
                that.$router.push({ name: 'Result' })
              } else {
                // 支付未完成
                console.log('支付未完成')
              }
            })
          }, 2000)
        }

        // 微信支付
        if (this.payType === 1) {
          this.qrcode = res.data

          // 开始计时分钟
          that.maxtimesetIVal = setInterval(() => {
            --that.maxtime
            if (that.maxtime < 0) {
              console.log('停了')
              clearInterval(that.maxtimesetIVal) // 清空倒计时
              clearInterval(that.setIVal) // 清空循环查询
            }
          }, 1000)

          // 轮询
          that.setIVal = setInterval(() => {
            queryWxOrder({ orderNo: that.orderNo }).then((res) => {
              console.log(res)
              if (res.msg !== '订单未支付') {
                // 支付完成
                console.log('支付完成')
                that.visible = false
                // 跳页面
                that.$router.push({ name: 'Result' })
              } else {
                // 支付未完成
                console.log('支付未完成')
              }
            })
          }, 2000)
        }
      })
    },
    handleModalOk() {},
    handleModalCancel() { this.visible = false },

    /**
     * @title 支付宝周期付款关闭
     */
    handleAlipayModalCancel() { this.alipayVisible = false }
  }
}
</script>

<style lang="scss" scoped>
p.price{
  color: #242833;
  font-size: 48px;
  line-height: 58px;
}
p.desc{
  margin-top: 8px;
  color: #7A8499;
  font-size: 16px;
  line-height: 22px;
}
.icon-weixin{
  color: #28C445;
  font-size: 28px;
  margin-right: 10px;
}
.icon-zhifubao{
  color: #02A9F1;
  font-size: 28px;
  margin-right: 10px;
}
h4 {
  font-size: 14px;
  line-height: 20px;
}
.formItemTitle{
  color: #242833;
  text-align: left;
  font-weight: 500;
  margin-bottom: 12px;
  margin-top: 12px;
}
.main {
  min-width: 510px;
  margin: 0 !important;
  box-sizing: border-box;
  display: flex;
  background-color: #f4f4f4;
  flex-direction: column;
  padding: 24px;
  .layout{
    margin: 0 auto;
    .return_btn{
      color: rgb(122, 132, 153);
      padding: 0;
      height: 22px;
      font-size: 12px;
      background-color: transparent;
      border-color: transparent;
      box-shadow: none;
      outline: 0;
    }
  }
}
.layout-main{
  display: flex;
  box-sizing: border-box;
  .layout-content{
    flex: 1;
  }
}

.package-pay-container{
  display: flex;
}
.package-pay-card-container{
  flex: 1;
  overflow: hidden;
}
.package-pay-small-card-container{
  width: 285px;
  margin-left: 20px;
}
.sm-card.card {
  margin-bottom: 20px;
  padding: 24px;
  border-radius: 6px;
  background: rgb(255, 255, 255);
  box-sizing: border-box;
  border: 1px solid #f0f0f0;
  color: #474F5E;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
}
.card-head{
  min-height: auto;
  padding: 0;
  line-height: 22px;
  border: none;
  margin-bottom: -1px;
  color: #242833;
  font-weight: 500;
  font-size: 16px;
  background: transparent;
  border-radius: 4px 4px 0 0;
  text-align: left;
  p{
    padding-bottom: 8px;
  }
}
.card-head::before {
  display: table;
  content: '';
  box-sizing: border-box;
}
.card-body{
  margin-top: 16px;
  padding: 0;
  &:before{
    display: table;
    content: '';
  }
  .card-container{
    position: relative;
    margin-top: 8px;
    padding-top: 22px;
    border-top: 1px solid #eef1f6;
  }
}
.discount-code-content{
  display: flex;
  padding-top: 16px;
  .inputBox{
    margin-right: 10px;
  }
  input{
    padding: 6px 12px;
    border-radius: 4px;
    color: #474F5E;
    caret-color: #356DFF;
    font-variant: tabular-nums;
    list-style: none;
    position: relative;
    font-feature-settings: 'tnum', "tnum";
    width: 100%;
    min-width: 0;
    font-size: 14px;
    background-color: #fff;
    background-image: none;
    border: 1px solid #D7DBE7;
    transition: all 0.3s;
  }
  input::placeholder{
    color: #c6c9d3;
    font-weight: 300;
  }
}
.total-container{
  display: flex;
  justify-content: space-between;
  line-height: 20px;
  box-sizing: border-box;
  padding-top: 8px;
}
.realPay-container {
  margin-top: 8px;
  border-top: 1px solid #D7DBE7;
}
.package-pay-footer-divider{
  margin: 0 0 20px 0;
  border-top: 1px solid #EEF1F6;
  border-top-color: #D7DBE7;
  display: flex;
  clear: both;
  width: 100%;
  min-width: 100%;
  box-sizing: border-box;
  padding: 0;
  color: #474F5E;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
}
</style>
